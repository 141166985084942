<template>
    <div>
        <div class="modal fade" id="ProductionScheduleStatusModal" tabindex="-1" aria-labelledby="ProductionScheduleStatusModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="ProductionScheduleStatusModalLabel" class="font-weight-bold">{{ModalTitle}}{{ProductionScheduleCode}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                        </div>
                        
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import productionScheduleServices from '../Script/ProductionScheduleServices.js';
import { response } from '../../../../infrastructure/constant/response.js';
import { sweet_alert } from '../../../../infrastructure/constant/variable.js';
import moment from 'moment';

export default {
    name: 'ProductionScheduleStatusForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',

            Id : '',
            ProductionScheduleCode : '',
            Status : '',
            StatusData : [],
        }
    },
    methods: {
        async statusClick(data){
            this.ModalTitle = 'Edit Status Production Schedule: ';
            this.Id = data.production_schedule_id;
            
            this.ProductionScheduleCode = data.production_schedule_code;
            
            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_production_schedule');
            this.Status = data.status;
            
            window.$('#ProductionScheduleStatusModal').modal('show');
        },
        async saveClick(){
            this.$loading(true);

            var data = await productionScheduleServices.getProductionScheduleQuery(this.Id);
            var gridScheduleData = productionScheduleServices.convertScheduleDetailSave(data.production_schedule_details, data.production_schedule_id);

            const productionScheduleData = {
                    production_schedule_id: data.production_schedule_id,
                    production_schedule_code: data.production_schedule_code,
                    production_schedule_date: data.production_schedule_date == null ? null : moment(data.production_schedule_date).format("YYYY-MM-DD"),
                    status: this.Status,
                    delivery_date: data.delivery_date == null ? null : moment(data.delivery_date).format("YYYY-MM-DD"),
                    production_datetime: data.production_datetime,
                    estimation_datetime: data.estimation_datetime,
                    additional_time: {},
                    production_schedule_details: gridScheduleData,
                    processed_schedule_details: []
                }
            
            const variables = {
                id : data.production_schedule_id,
                data : productionScheduleData
            }
            
            this.$swal(sweet_alert.update_status).then((result) => {
                if (result.isConfirmed == true) {
                    productionScheduleServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        window.$('#ProductionScheduleStatusModal').modal('hide');
                        this.$swal("Info", response.successUpdate, "success");
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            });

            this.$loading(false);
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>